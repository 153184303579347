html {
  box-sizing: border-box;
  font-size: 62.5%;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  text-rendering: optimizelegibility;
  min-height: 1280px;
  background: #f9fafb;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 1.5rem;
  line-height: 1.6;
  overflow-x: hidden;
}

*, :before, :after {
  box-sizing: inherit;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  white-space: nowrap;
  margin-top: 0;
  margin-bottom: 2.5rem;
  font-weight: 600;
}

p {
  margin: 0;
  padding-bottom: 12px;
}

.italics {
  font-style: italic;
}

.bold {
  font-weight: 600;
}

.grey {
  color: #718096;
}

.intro {
  max-width: 1000px;
  margin: 3rem auto 1rem;
}

.intro-header {
  margin: 0 auto;
  font-size: 2em;
}

.intro-description {
  color: #718096;
}

#scrolly {
  max-width: 1000px;
  margin: 0 auto 200px;
  display: flex;
  position: relative;
}

#scrolly > * {
  flex: 1;
}

article {
  padding-right: 5rem;
  position: relative;
}

figure {
  width: 100%;
  z-index: 0;
  margin: 0;
  position: sticky;
  transform: translate3d(0, 0, 0);
}

.step {
  opacity: .3;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 2rem;
  transition: opacity .23s;
  display: flex;
}

.step:last-child {
  margin-bottom: 0;
}

.step.is-active {
  opacity: 1;
}

.step p {
  font-size: 1.5rem;
  line-height: 1.6;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.highlight {
  background: #fefcbf;
}

.highlight-weight {
  color: #48bb78;
}

.highlight-weight .visual {
  border-width: 2px;
}

.highlight-bias {
  color: #4299e1;
}

.highlight-bias .visual {
  border-width: 2px;
}

.highlight-error {
  color: #f56565;
}

.offer {
  width: 250px;
  backface-visibility: hidden;
  padding: 30px;
  font-size: 18px;
  transition: opacity .23s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: perspective(800px)rotateY(20deg)rotateX(10deg)scaleX(.95)translate(-50%, -55%);
  box-shadow: -5px 18px 40px -12px #0006;
}

.offer h1 {
  margin: 0 0 1rem;
  line-height: 1;
}

.offer h4 {
  margin: 0 0 1rem;
}

.line {
  width: 100%;
  height: 20px;
  background-color: #e2e8f0;
  margin-bottom: 1rem;
}

.table-container {
  width: 100%;
  position: absolute;
}

.table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  transition: opacity .23s;
}

.me-line {
  width: 110%;
  z-index: 1;
  border-top: 1px dashed #2d3748;
  transition: all .3s;
  position: relative;
  left: -40px;
}

.me-line:before {
  content: "Me";
  background: #f9fafb;
  padding-right: 10px;
  position: absolute;
  top: -15px;
}

.plus-line {
  position: absolute;
  bottom: -85px;
  left: 0;
  right: 0;
}

.line-container {
  margin-bottom: 1.5rem;
  display: flex;
}

.experience-line {
  border-top: 1px solid #2d3748;
  margin-left: 100px;
  transition: opacity .23s;
}

.salary-line {
  border-top: 1px solid #2d3748;
  margin-left: 150px;
  transition: opacity .23s;
}

.total {
  text-align: right;
  transition: opacity .23s;
}

.total-value {
  font-weight: 600;
}

.table tbody tr {
  transition: transform .23s;
}

.table tbody tr:nth-child(2n) {
  background: #e2e8f0;
}

.table tbody tr td {
  padding: .5rem;
}

.table tbody tr td:last-child:before {
  content: "$";
}

.formula-container {
  width: 100%;
  text-align: center;
  background: #f9fafbb5 url("arrow.c1f88b75.svg") 50% 25px no-repeat;
  justify-content: space-evenly;
  align-items: center;
  transition: opacity .23s;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.visual {
  width: 50px;
  height: 50px;
  z-index: 1;
  background: #f9fafb;
  border: 1px solid;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto 1rem;
  line-height: 1;
  display: flex;
  position: relative;
}

.visual-description {
  color: #718096;
}

.weight, .bias {
  padding: 1rem;
  transition: all .23s;
}

.bias {
  transform: translateX(-30px);
}

.test {
  background: #f9fafb;
  border: 1px solid;
  padding: .3rem .5rem;
  transition: opacity .23s;
  position: absolute;
  top: 20px;
  left: -10px;
}

.chart-container, .chart-error, .chart-weight, .chart-bias {
  transition: opacity .23s;
}

.chart {
  margin-bottom: 20px;
}

.chart-controls {
  justify-content: space-between;
  display: flex;
}

.chart .xAxis path, line, .chart .yAxis path, line {
  stroke: #a0aec0;
}

.chart circle {
  fill: #a0aec0;
}

.chart path.regression-line {
  stroke: #48bb78;
  fill: none;
  stroke-width: 1.2px;
}

.chart line.error-line {
  stroke: #f56565;
  fill: none;
  stroke-width: 1px;
}

.tooltip {
  color: #fff;
  text-align: center;
  width: 60px;
  pointer-events: none;
  background: #000c;
  border-radius: 4px;
  padding: 5px;
  font-size: 10px;
  position: absolute;
}

@media screen and (max-width: 600px) {
  main {
    padding: 0 12px;
  }

  article {
    padding: 0;
  }

  figure {
    position: fixed;
  }

  .step p {
    background: #fff;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .step p:first-child {
    padding-top: 12px;
  }

  .step p:last-child {
    padding-bottom: 12px;
  }

  .step.is-active {
    z-index: 1;
    position: relative;
  }

  #scrolly {
    flex-direction: column;
  }

  .total {
    text-align: left;
  }
}

/*# sourceMappingURL=index.efe6a91e.css.map */
